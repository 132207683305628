import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Power Clean 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`“Grelen”`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`400M Run`}</p>
    <p>{`10-Clean & Jerks (135/95)`}</p>
    <p>{`21-KBS’s (53/35)`}</p>
    <p>{`12-Pullups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar with Dawn from Sports Nutrition 2 Go in Cincinnati.
The seminar will be from 12-2pm. \\$35/person for the seminar. We will
need 20 people in order to have this so please email
Daniel\\@crossfitlouisvilleeast.com if you’d like to attend.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Subjects covered will include:`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Hydration—How impacts performance, electrolytes, what to drink`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Breakfast—effects afternoon performance by 5% and effects
appetite`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Nutrient Timing—what to eat before/after, when`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Sleep and Nutrition on performance---Discuss percentages on
outcomes`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Discuss different nutrition philosophies`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Supplements and Labs: what to take and which labs to draw`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Also: She will cover what ever the audience would like! If you would
like to attend and you want to forward questions ahead of time please
send them to: Daniel\\@crossfitlouisvilleeast.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Want to learn the exact number of calories your body burns each
day? Metabolic Testing will be offered before and after the seminar as
well! \\$65 for Metabolic Testing.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      